// extracted by mini-css-extract-plugin
export var content = "philosophy-module--content--23f32";
export var howContainer = "philosophy-module--how-container--062e4";
export var image = "philosophy-module--image--1859c";
export var item = "philosophy-module--item--b06cb";
export var itemEven = "philosophy-module--item-even--af27d";
export var itemOdd = "philosophy-module--item-odd--03916";
export var section = "philosophy-module--section--a19f3";
export var title = "philosophy-module--title--9a204";
export var titleSection = "philosophy-module--title-section--35277";
export var whoContainer = "philosophy-module--who-container--6d6a1";