import { object } from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Wrap } from '../sections-wrap';
import * as styles from './philosophy.module.scss';

function Who({ data }) {
  return (
    <div className={styles.whoContainer}>
      {data.map((item, i) => (
        <div
          className={i % 2 === 0 ? styles.itemEven : styles.itemOdd}
          key={item.title}
        >
          <Wrap>
            <GatsbyImage
              image={getImage(item.image.gatsbyImage)}
              alt=""
              className={styles.image}
            />
            <div className={styles.content}>
              <h2>{item.title}</h2>
              <div dangerouslySetInnerHTML={{ __html: item.content }} />
            </div>
          </Wrap>
        </div>
      ))}
    </div>
  );
}

Who.propTypes = {
  data: object,
};

Who.defaultProps = {
  data: {},
};

export { Who };
