import { object } from 'prop-types';
import { graphql } from 'gatsby';
import { Who, How } from '../../../components/philosophy/index';
import { Wrap } from '../../../components/sections-wrap';
import * as styles from './index.module.scss';

function Philosophy({ data }) {
  const mainData = data?.wpPage?.template?.philosophyPageConfiguration;
  const whoData = mainData?.eaPhilosophyWho;
  const howData = mainData?.eaPhilosophyHow;
  const howTitle = mainData?.eaPhilosophyHowTitle;
  return (
    <div className={styles.pageContainer}>
      <Wrap>
        <main className={styles.titleContainer}>
          <article>
            <header>
              <div className={styles.title}>
                <h1>More Than Just Lawyers</h1>
              </div>
            </header>
          </article>
        </main>
      </Wrap>

      <Who data={whoData} />

      <How data={howData} title={howTitle} />

    </div>
  );
}

export const query = graphql`
  {
    wpPage(uri: {eq: "/about/philosophy/"}) {
      ...SeoPage
      title
      template {
        ... on WpTemplate_Philosophy {
          philosophyPageConfiguration {
            eaPhilosophyHow {
              content
              title
            }
            eaPhilosophyHowTitle
            eaPhilosophyWho {
              content
              title
              image {
                gatsbyImage(width:800)
                altText
              }
            }
          }
        }
      }
    }
  }
`;

Philosophy.propTypes = {
  data: object,
};

Philosophy.defaultProps = {
  data: {},
};

export default Philosophy;
export { Head } from '../../../components/seo/seo';
