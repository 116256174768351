import { object, string } from 'prop-types';
import { Wrap } from '../sections-wrap';
import * as styles from './philosophy.module.scss';

function How({ data, title }) {
  return (
    <div className={styles.howContainer}>
      <Wrap>
        <div>
          <h2 className={styles.titleSection}>
            {title}
          </h2>
        </div>
        <div>
          {data.map((item) => (
            <div className={styles.section} key={item.title}>
              <h2 className={styles.title}>
                {item.title}
              </h2>
              <div className={styles.content}>
                <div dangerouslySetInnerHTML={{ __html: item.content }} />
              </div>
            </div>
          ))}
        </div>
      </Wrap>
    </div>
  );
}

How.propTypes = {
  data: object,
  title: string,
};

How.defaultProps = {
  data: {},
  title: '',
};

export { How };
